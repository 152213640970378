/**
 * Alert Model
 */
export class Alert {
    public id: string;

    constructor(
        public type: string,
        public message: string
    ) {
        this.id = String(new Date().getTime());
    }
}
