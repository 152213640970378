/**
 * Alert Item Component Class
 *
 * @example to create new Alert:
 * this.props.dispatch(addAlert(new Alert(AlertTypes.DANGER, 'new Error')))
 */
import React, { Component } from 'react';
import { AlertTypes }       from './alertTypes';
import { Dispatch }         from 'redux';
import { Store }            from '../../types/store';
import { connect }          from 'react-redux';
import './Alert.scss';
import { AlertItem }        from './alertItem';
import { removeAlert }      from '../../ducks/alerts';

interface AlertItemComponentProps {
    id: string
    type: string;
    message: string;
    dispatch: Dispatch;
}

class Alert extends Component<AlertItemComponentProps> {
    alert: AlertItem;
    timer: any;

    constructor(props: AlertItemComponentProps) {
        super(props);

        this.alert = {
            id     : this.props.id,
            type   : this.props.type,
            message: this.props.message
        };

        this.removeAlertItem = this.removeAlertItem.bind(this);
    }

    colorClassModifier(): string {
        switch (this.props.type) {
            case AlertTypes.DANGER:
                return AlertTypes.DANGER.toLowerCase();
            case AlertTypes.INFO:
                return AlertTypes.INFO.toLowerCase();
            case AlertTypes.SUCCESS:
                return AlertTypes.SUCCESS.toLowerCase();
            case AlertTypes.WARNING:
                return AlertTypes.WARNING.toLowerCase();
            default:
                return '';
        }
    }

    removeAlertItem(): void {
        this.props.dispatch(removeAlert(this.alert));
    }

    render() {
        return (
            <div className={`c-alert c-alert--${this.colorClassModifier()}`}>
                <span className="c-alert__heading">{this.props.message}</span>
                <i
                    className={`e-icon--close e-icon--close-${this.colorClassModifier()}`}
                    onClick={this.removeAlertItem}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: Store) => ({state});

export default connect(mapStateToProps)(Alert);
