import { Translation, TranslationId } from '../types/translation';
import { Action }                     from '../types/action';

enum TranslationsActionTypes {
    SET                 = 'translation-ui/translations/SET',
    SET_MORE            = 'translation-ui/translations/SET_MORE',
    REMOVE              = 'translation-ui/translations/REMOVE',
    SET_NEW_TRANSLATION = 'translation-ui/translations/SET_NEW_TRANSLATION',
    UPDATE_TRANSLATION  = 'translation-ui/translations/UPDATE_TRANSLATION',
    CLEAR_TRANSLATIONS  = 'translation-ui/translations/CLEAR_TRANSLATIONS'
}

/**
 * Set Translations
 *
 * @param {Translation[]} translations
 * @return {{data: Translation[]; type: TranslationsActionTypes}}
 */
export const setTranslations = (translations: Translation[]) => ({
    type: TranslationsActionTypes.SET,
    data: translations
});

export const setMoreTranslations = (translations: Translation[]) => ({
    type: TranslationsActionTypes.SET_MORE,
    data: translations
});

export const setNewTranslation = (translation: Translation) => ({
    type: TranslationsActionTypes.SET_NEW_TRANSLATION,
    data: translation
});

export const updateTranslation = (id: TranslationId, value: string) => ({
    type: TranslationsActionTypes.UPDATE_TRANSLATION,
    data: {id, value}
});

export const clearTranslations = () => ({
    type: TranslationsActionTypes.CLEAR_TRANSLATIONS,
    data: []
});

/**
 * Remove Translation
 *
 * @param {TranslationId} id
 * @return {{data: TranslationId; type: TranslationsActionTypes}}
 */
export const removeTranslation = (id: TranslationId) => ({
    type: TranslationsActionTypes.REMOVE,
    data: id
});

interface UpdatedInfo {
    id: TranslationId;
    value: string;
}

type Data = Translation[] | TranslationId | Translation | UpdatedInfo;

/**
 * Reducer
 *
 * @param {Translation[]} state
 * @param {Action<TranslationsActionTypes, Data>} action
 * @return {Translation[]}
 */
const translationsReducer = (state: Translation[] = [], action: Action<TranslationsActionTypes, Data>) => {
    switch (action.type) {
        case TranslationsActionTypes.SET:
            return [...action.data as Translation[]];
        case TranslationsActionTypes.SET_MORE:
            return [
                ...state,
                ...action.data as Translation[]
            ];
        case TranslationsActionTypes.SET_NEW_TRANSLATION:
            const iFirstNonGlobal = state.findIndex(t => t.carrierId !== null);
            return [
                ...state.slice(0, iFirstNonGlobal),
                action.data as Translation,
                ...state.slice(iFirstNonGlobal)
            ];
        case TranslationsActionTypes.REMOVE:
            const iRemoved = state.findIndex(t => t.id === action.data as TranslationId);
            return [
                ...state.slice(0, iRemoved),
                ...state.slice(iRemoved + 1)
            ];
        case TranslationsActionTypes.UPDATE_TRANSLATION:
            const iUpdated = state.findIndex(t => t.id === (action.data as UpdatedInfo).id);
            return [
                ...state.slice(0, iUpdated),
                {
                    ...state[iUpdated],
                    value: (action.data as UpdatedInfo).value
                },
                ...state.slice(iUpdated + 1)
            ];
        case TranslationsActionTypes.CLEAR_TRANSLATIONS:
            return [];
        default:
            return state;
    }
};

export default translationsReducer;


