import React, { PureComponent } from 'react';
import Button                   from '../../components/Button';
import { appSettings }          from '../../app.settings';
import { connect }              from 'react-redux';
import { mapStateToProps }      from '../../helpers';
import { ReduxComponentProps }  from '../../types/reduxComponentProps';
import { openPopup }            from '../../ducks/popup';
import { PopupType }            from '../../enums/popupType';

interface AddNewTranslationBtnProps extends ReduxComponentProps {}

@connect(mapStateToProps)
class AddNewTranslationBtn extends PureComponent<AddNewTranslationBtnProps> {

    constructor(props: AddNewTranslationBtnProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(): void {
        this.props.dispatch(openPopup(PopupType.ADD));
    }

    render(): React.ReactNode {
        return <Button onClick={this.onClick}>{appSettings.text.add_new_translation}</Button>;
    }
}

export default AddNewTranslationBtn;
