/**
 * Search Component Class
 */
import React, { PureComponent } from 'react';
import './Search.scss';
import icons                    from '../../icons';
import Spinner                  from '../Spinner';

interface SearchProps {
    placeholder: string;
    class?: string;
    spinner?: boolean;
    onSearch?: (search: string) => void;
    value?: string;
}

interface SearchState {
    value: string;
}

class Search extends PureComponent<SearchProps, SearchState> {

    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: SearchProps) {
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            value: ''
        };

        this.onSearch = this.onSearch.bind(this);
        this.clear    = this.clear.bind(this);
    }

    componentWillReceiveProps(nextProps: Readonly<SearchProps>, nextContext: any): void {
        if (nextProps.value === '') {
            this.inputRef.current.value = '';
            this.setState({value: ''});
        }
    }

    /**
     * OnSearch Handler
     *
     * @param {React.KeyboardEvent} e
     */
    onSearch(e: React.KeyboardEvent): void {
        const value = (e.target as HTMLInputElement).value;

        if (this.state.value !== value) {
            this.setState({value});
            if (this.props.onSearch) this.props.onSearch(value);
        }
    }

    clear(e: React.MouseEvent<HTMLElement>): void {
        e.stopPropagation();
        this.inputRef.current.value = '';
        this.setState({value: ''});
        if (this.props.onSearch) this.props.onSearch('');
    }

    /**
     * Render
     *
     * @return {React.ReactNode}
     */
    render(): React.ReactNode {
        return (
            <div className={`c-search ${this.props.class ? this.props.class : ''}`}>
                <i className="c-search__icon--search">{icons.SEARCH}</i>

                <input
                    ref={this.inputRef}
                    className="c-search__input"
                    type="text"
                    placeholder={this.props.placeholder}
                    autoFocus={true}
                    onKeyUp={this.onSearch}
                />

                {
                    this.state.value !== ''
                    && <i
                        className="c-search__icon--clear"
                        onClick={this.clear}
                    >{icons.CLOSE}
                    </i>
                }

                {
                    this.props.spinner && <Spinner class="c-search__spinner"/>
                }

            </div>

        );
    }
}

export default Search;
