import React                from 'react';
import { appSettings }      from '../app.settings';
import AddNewTranslationBtn from '../containers/createAndEdit/AddNewTranslationBtn';

const Header = () => (
    <header className="l-flex l-flex--align-center l-flex--justify-space-between">
        <h1 className="p-library__heading e-heading e-heading--h1">{appSettings.text.translation_service}</h1>
        <AddNewTranslationBtn/>
    </header>
);

export default Header;
