import React            from 'react';
import { TableColumns } from '../../enums/tableColumns';

interface TableHeaderCellProps {
    columnIndex: number;
    columnLabel: string;
    style: any;
    heading: string;
}

const TableHeaderCell = (props: TableHeaderCellProps) => (
    <span
        className={`c-table__heading-cell
                    ${props.columnLabel === TableColumns.ROW_NUMBER ? 'c-table__heading-cell--center' : ''}
        `}
        style={props.style}
    >{props.heading}
    </span>
);

export default TableHeaderCell;
