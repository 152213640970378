import { Filter }          from '../types/filter';
import { Action }          from '../types/action';
import { getTranslations } from './dispatchers';
import { clearTranslations}         from './translations';
import { setPopupDealers, } from './popup';
import { getDealers }  from './dispatchers';

enum FilterActionTypes {
    SET = 'translation-ui/filter/SET',
    CLEAR = 'translation-ui/filter/CLEAR',
}

/**
 * Set Filter Option
 *
 * @param {Filter} filter
 * @return {(dispatch) => void}
 */

export const setFilterOptions: any = (filter: Filter) => dispatch => {
    dispatch({
        type: FilterActionTypes.SET,
        data: filter
    });
    if (filter.carrierId) {
        dispatch(getDealers(filter.carrierId, setPopupDealers));
        sleep(100).then(() => {
          dispatch(getTranslations());
       });

    } else {
        dispatch(getTranslations());
    }
};

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const clearFilter: any = (filter: Filter) => dispatch => {
    dispatch({
        type: FilterActionTypes.CLEAR,
        data: initialState
    });
    dispatch(clearTranslations());
};

const initialState: Filter = {
    carrierId: null,
    dealerId : null,
    search   : ''
};

/**
 * Reducer
 *
 * @param {Filter} state
 * @param {Action<FilterActionTypes, Filter>} action
 * @return {any}
 */
const filterReducer = (state: Filter = initialState, action: Action<FilterActionTypes, Filter>) => {
    switch (action.type) {
        case FilterActionTypes.SET:
            return {...action.data};
        case FilterActionTypes.CLEAR:
            return initialState;
        default:
            return state;
    }
};

export default filterReducer;

