import React         from 'react';
import DealerFilter  from '../containers/filters/DealerFilter';
import CarrierFilter from '../containers/filters/CarrierFilter';
import SearchFilter from '../containers/filters/SearchFilter';
import '../styles/_components.filters.scss';

const Filters = () => (
    <div className="c-filters">
        <CarrierFilter/>
        <DealerFilter/>
        <SearchFilter/>
    </div>
);

export default Filters;
