import { PopupType }   from '../enums/popupType';
import { IPopup }      from '../types/popup';
import { Action }      from '../types/action';
import { Translation } from '../types/translation';
import { Dealer }      from '../types/dealer';

enum PopupActionTypes {
    IS_VISIBLE            = 'translation-ui/popup/IS_VISIBLE',
    IS_HIDDEN             = 'translation-ui/popup/IS_HIDDEN',
    SET_TRANSLATION_VALUE = 'translation-ui/popup/SET_TRANSLATION_VALUE',
    SET_DEALERS           = 'translation-ui/popup/SET_DEALERS',
    CLEAR_DEALERS         = 'translation-ui/popup/CLEAR_DEALERS'
}

/**
 * Open Popup
 *
 * @param {PopupType} popupType
 * @param translation
 * @return {{data: PopupType; type: PopupActionTypes}}
 */
export const openPopup = (popupType: PopupType, translation: Translation = null) => ({
    type: PopupActionTypes.IS_VISIBLE,
    data: {popupType, translation}
});

/**
 * Close Popup
 *
 * @return {{type: PopupActionTypes}}
 */
export const closePopup = () => ({
    type: PopupActionTypes.IS_HIDDEN
});

export const setTranslationValue = (translationProperty: string, translationValue: string | number) => ({
    type: PopupActionTypes.SET_TRANSLATION_VALUE,
    data: {translationProperty, translationValue}
});

export const setPopupDealers = (dealers: Dealer[]) => ({
    type: PopupActionTypes.SET_DEALERS,
    data: dealers
});

export const clearPopupDealers = () => ({
    type: PopupActionTypes.CLEAR_DEALERS
});

const initialState: IPopup = {
    isOpen     : false,
    type       : null,
    dealers    : [],
    translation: null,
    oldValue   : ''
};

/**
 * Reducer
 *
 * @param {IPopup} state
 * @param {Action<PopupActionTypes, PopupType>} action
 * @return {any}
 */
const popupReducer = (state: IPopup = initialState, action: Action<PopupActionTypes, any>) => {
    switch (action.type) {
        case PopupActionTypes.IS_VISIBLE:
            return {
                isOpen     : true,
                type       : action.data.popupType,
                dealers    : state.dealers,
                translation: action.data.translation,
                oldValue   : action.data.translation ? action.data.translation.value : null
            };
        case PopupActionTypes.IS_HIDDEN:
            return {...initialState, dealers: state.dealers};
        case PopupActionTypes.SET_TRANSLATION_VALUE:
            return {
                ...state,
                translation: {
                    ...state.translation,
                    [action.data.translationProperty]: action.data.translationValue
                }
            };
        case PopupActionTypes.SET_DEALERS:
            return {
                ...state,
                dealers: action.data
            };
        case PopupActionTypes.CLEAR_DEALERS:
            return {
                ...state,
                dealers: []
            };
        default:
            return state;
    }
};

export default popupReducer;
