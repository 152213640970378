import { TableColumns } from './enums/tableColumns';

export const appSettings = {

    pageLimit: 500,

    languageDropdownOptions: [
        {label: 'en', heading: 'English'},
        {label: 'fr', heading: 'French'}
    ],

    text: {
        add_new_translation: 'Add New Translation',
        edit_translation   : 'Edit The Translation',
        translation_service: 'Translation Service',
        label              : 'Label',
        enter_label        : 'Enter a Label',
        value              : 'Value',
        enter_value        : 'Enter a Value',

        search_language : 'Search Language',
        select_dealer   : 'Select Dealer',
        search_a_dealer : 'Search a Dealer',
        select_carrier  : 'Select Carrier',
        search_a_carrier: 'Search a Carrier',

        apply                      : 'Apply',
        search                     : 'Search',
        language                   : 'Language',
        logout                     : 'Logout',
        no_results                 : 'No Results',
        for                        : 'for',
        dealer                     : 'Dealer',
        carrier                    : 'Carrier',
        select_language            : 'Select language',
        template                   : 'Template',
        start_typing_your_message  : 'Start typing your message',
        filter_to_start_seeing_data: 'Filter to start seeing data',
        edit                       : 'Edit',
        view                       : 'View',
        delete                     : 'Delete',
        remove                     : 'Remove',
        enter_name                 : 'Enter name',
        name                       : 'Name',
        yes                        : 'Yes',
        cancel                     : 'Cancel',
        save                       : 'Save',

        success_translation_created: 'Translation Created',
        success_removed            : 'Translation Removed',

        error_already_exist: 'The label already exists for the specified combination of Carrier, Dealer, and language',
        error_permissions  : 'You do not have permission to add/edit translations. Contact your system administrator.',
        error_server       : 'Oops, something went wrong. Try later or contact your system administrator.',

        // Tag
        global_level_translation: 'Global Level Translation',
        custom                  : 'Global',


        confirmation                   : 'Confirmation',
        are_you_sure_you_want_to_remove: 'By removing this translation, it will no longer appear in the app for users. Continue?',
        custom_fields_hint_message     : 'If you have custom fields you want to use, format it as {{instructions}}. For example: "How is your {{device}} working out?”'
    },

    tableColumns: [
        {heading: '#', label: TableColumns.ROW_NUMBER, width: 1},
        {heading: 'Language', label: TableColumns.LANGUAGE, width: 1},
        {heading: 'Carrier', label: TableColumns.CARRIER, width: 2},
        {heading: 'Dealer', label: TableColumns.DEALER, width: 2},
        {heading: 'Label', label: TableColumns.LABEL, width: 3},
        {heading: 'Value', label: TableColumns.VALUE, width: 4},
        {heading: '', label: TableColumns.ACTIONS, width: 3}
    ]
};
