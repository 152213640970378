import React from 'react';
import './Spinner.scss';

const Spinner = (props: {class?: string}) => (
    <div className={`c-spinner ${props.class ? props.class : ''}`}>
        <div className="c-spinner__wrapper">
            <div className="c-spinner__circle"/>
            <div className="c-spinner__circle"/>
            <div className="c-spinner__circle"/>
            <div className="c-spinner__circle"/>
        </div>
    </div>
);


export default Spinner;
