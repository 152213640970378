/**
 * Action Types
 */
import { Loading }    from '../types/loading';

enum LoadingActionTypes {
    IS_TRUE  = 'translation-ui/loading/IS_TRUE',
    IS_FALSE = 'translation-ui/loading/IS_FALSE'
}

/**
 * Actions
 * ===========================================================================
 */
/**
 * Action of setting the loading to True
 *
 * @return {{type: LoadingActionTypes}}
 */
export const loadingIsTrue = () => (
    {
        type: LoadingActionTypes.IS_TRUE
    }
);

/**
 * Action of setting the loading to False
 *
 * @return {{type: LoadingActionTypes}}
 */
export const loadingIsFalse = () => (
    {
        type: LoadingActionTypes.IS_FALSE
    }
);

/**
 * Reducer
 *
 * @param {Loading} state
 * @param {{type: string}} action
 * @return {boolean}
 */
const loadingReducer = (state: Loading = false, action: { type: LoadingActionTypes }) => {
    switch (action.type) {
        case LoadingActionTypes.IS_TRUE:
            return true;
        case LoadingActionTypes.IS_FALSE:
            return false;
        default:
            return state;
    }
};

export default loadingReducer;
