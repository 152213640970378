/**
 * Sidebar Navigation Component Class
 */
import React, { PureComponent } from 'react';
import icons                    from '../../icons';
import './SidebarNav.scss';
import { appSettings }          from '../../app.settings';

interface SidebarNavComponentProps {

}

interface SidebarNavComponentState {
    isSidebarOpen: boolean;
}

class SidebarNav extends PureComponent<SidebarNavComponentProps, SidebarNavComponentState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isSidebarOpen: false
        };

        this.openSidebar  = this.openSidebar.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);
    }

    openSidebar(): void {
        this.setState({isSidebarOpen: true});
    }

    closeSidebar(): void {
        this.setState({isSidebarOpen: false});
    }

    render() {
        return (
                <nav className="c-sidebar-nav">
                    <button className="c-sidebar-nav__hamburger" onClick={this.openSidebar}>{icons.HAMBURGER}</button>

                    {
                        this.state.isSidebarOpen &&
                        <div className="c-sidebar-nav__bg">
                            <i className="c-sidebar-nav__close" onClick={this.closeSidebar}>{icons.CLOSE}</i>
                        </div>
                    }


                    <div
                        className={`c-sidebar-nav__wrapper ${this.state.isSidebarOpen ? 'c-sidebar-nav__wrapper--open' : ''}`}
                    >
                        <ul className="c-sidebar-nav__list c-sidebar-nav__list--top">
                            <li className="c-sidebar-nav__item c-sidebar-nav__item--logo"><span>STATFLO</span></li>
                        </ul>

                        <ul className="c-sidebar-nav__list c-sidebar-nav__list--bottom">
                            <a className="c-sidebar-nav__link" href="/">
                                <li className="c-sidebar-nav__item">{icons.LOGOUT}
                                    <span>{appSettings.text.logout}</span>
                                </li>
                            </a>
                        </ul>
                    </div>
                </nav>
        );
    }
}

export default SidebarNav;
