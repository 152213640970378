import { UserSession } from "../../../ui/src/types";
import Cache from "../helpers/cache";

interface TokenErrorResponse {
    code: number;
    data: {
        error: string;
        code: number;
    }
}

interface TokenPayload {
    token: string;
    info: any[];
    user: UserSession;
}

type TokenResponse = TokenPayload & TokenErrorResponse;

const TOKEN_CACHE_TTL = 600000; // 10 minutes in ms
const cache           = new Cache();

const getJWTCookie = (cookie: string) => cookie.match('crm.auth.jwt');
export const getSessionToken = (): string => {
    const cookies   = window.document.cookie.split(/\s*;\s*/);
    const phpCookie = cookies.length && cookies.filter(getJWTCookie);

    if (phpCookie.length) {
        return phpCookie[0].split('=')[1];
    }

    return null;
};

export function createSessionInstance(sessionHeaders) {
    // unfetch polyfill compatibility (for IE)
    return window.hasOwnProperty('Headers') ? new Headers(sessionHeaders) : sessionHeaders;
}

export async function getTokenPayload(): Promise<TokenPayload> {
    const oldTokenPayload = cache.get('tokenPayload');

    if (oldTokenPayload && oldTokenPayload.value !== null) {
        return oldTokenPayload.value;
    }

    const sessionToken = getSessionToken();

    const response = await fetch(`${STFLO_ENV_VARS.WEBAPP_HOST}/v2/api/auth/me`, ({
        method     : 'GET',
        cache      : 'no-cache',
        ...(sessionToken && {
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionToken}`
            })
        })
    }));


    const tokenPayload: TokenResponse = await response.json();

    if (!response.ok) {
        throw {
            message: `${tokenPayload.code} - ${tokenPayload.data.error}`
        };
    }

    const tokenPayloadCache = {
        value: tokenPayload,
        key  : 'tokenPayload',
        ttl  : TOKEN_CACHE_TTL
    };

    cache.put(tokenPayloadCache);
    return tokenPayloadCache.value;
}


export async function getToken(): Promise<string> {
    return await getTokenPayload().then((res: TokenPayload) => res.token);
}

export async function getUserLanguage(): Promise<string> {
    return await getTokenPayload().then((res: TokenPayload) => res.user.user_language);
}

export default getSessionToken;
