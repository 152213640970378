export enum ButtonSize {
    LARGE  = 'lg',
    MEDIUM = 'md',
    SMALL  = 'sm'
}

export enum ButtonType {
    OUTLINE      = 'outline',
    REGULAR      = 'regular',
    DEEMPHASIZED = 'deemphasized'
}

export enum ButtonColor {
    PRIMARY = 'primary'
}
