import DataService                                      from '../services/dataService';
import { setError }                                     from './errors';
import { setCarriers }                                  from './carriers';
import { Store }                                        from '../types/store';
import { Translation, TranslationId, TranslationInput } from '../types/translation';
import {
    clearTranslations, removeTranslation, setMoreTranslations, setTranslations, updateTranslation
}                                                       from './translations';
import { closePopup }                                   from './popup';
import { TableColumns }                                 from '../enums/tableColumns';
import { CarrierId }                                    from '../types/carrier';
import { appSettings }                                  from '../app.settings';
import { PageInfoQuery }                                from '../types/pageInfoQuery';
import { addAlert }                                     from './alerts';
import { Alert }                                        from '../../../../crm/reptracker-ui/src/models/alert.model';
import { AlertTypes }                                   from '../components/Alerts/alertTypes';
import { StatusCode }                                   from '../enums/statusCode';

enum DispatcherTypes {
    GET_DEALERS        = 'translation-ui/dispatchers/GET_DEALERS',
    GET_CARRIERS       = 'translation-ui/dispatchers/GET_CARRIERS',
    GET_TRANSLATIONS   = 'translation-ui/dispatchers/GET_TRANSLATIONS',
    DELETE_TRANSLATION = 'translation-ui/dispatchers/DELETE_TRANSLATION',
    CREATE_TRANSLATION = 'translation-ui/dispatchers/CREATE_TRANSLATION',
    EDIT_TRANSLATION   = 'translation-ui/dispatchers/EDIT_TRANSLATION'
}

/**
 * Get Dealers
 *
 * @return {(dispatch) => void}
 */
export const getDealers: any = (carrierId: CarrierId, setAction) => dispatch => {
    dispatch({type: DispatcherTypes.GET_DEALERS});

    DataService.getInstance()
        .getDealers(carrierId)
        .then(dealers => {
            dispatch(setAction(dealers));
        })
        .catch(error => dispatch(setError(error)));
};

/**
 * Get Carriers
 *
 * @return {(dispatch) => void}
 */
export const getCarriers: any = () => dispatch => {
    dispatch({type: DispatcherTypes.GET_CARRIERS});

    DataService.getInstance()
        .getCarriers()
        .then(carriers => dispatch(setCarriers(carriers)))
        .catch(error => dispatch(setError(error)));
};

/**
 * Get Translations
 *
 * @return {(dispatch, getState) => void}
 */
export const getTranslations: any = () => (dispatch, getState) => {
    dispatch({type: DispatcherTypes.GET_TRANSLATIONS});

    const state: Store = getState();

    if (!state.filter.carrierId && !state.filter.search) {
        dispatch(clearTranslations());
        return;
    }

    if (state.carriers.length === 0) dispatch(getCarriers());
    if (state.dealers.length === 0) dispatch(getDealers(state.filter.carrierId));

    const limit                   = appSettings.pageLimit;
    let page                      = 0;
    const pageInfo: PageInfoQuery = {
        offset: page * limit,
        limit
    };

    const getMoreTranslations = (more: boolean = false) => {
        const setTranslationsAction = more ? setMoreTranslations : setTranslations;
        DataService.getInstance()
            .getTranslations(state.filter, pageInfo)
            .then((translations: Translation[]) => {
                const data: Translation[] = translations.map(t => ({
                    ...t,
                    dealerName : state.allDealers.length > 0 ? state.allDealers.find(d => d.id === t.dealerId) && state.allDealers.find(d => d.id === t.dealerId).name : null,
                    carrierName: state.carriers.length > 0 ? state.carriers.find(c => c.id === t.carrierId) && state.carriers.find(c => c.id === t.carrierId).name : null
                }));

                dispatch(setTranslationsAction(data));

                if (data.length === limit) {
                    page++;
                    pageInfo.offset = page * limit;
                    getMoreTranslations(true);
                }
            })
            .catch(error => setError(error));
    };

    getMoreTranslations();
};

/**
 * Delete Translation
 *
 * @param {TranslationId} id
 * @return {(dispatch) => void}
 */
export const deleteTranslation: any = (id: TranslationId) => dispatch => {
    dispatch({type: DispatcherTypes.DELETE_TRANSLATION});

    DataService.getInstance()
        .deleteTranslation(id)
        .then(_ => {
            dispatch(removeTranslation(id));
            dispatch(addAlert(new Alert(AlertTypes.SUCCESS, appSettings.text.success_removed)));
        })
        .catch(error => errorHandler(error, dispatch));
};

export const createTranslation: any = () => (dispatch, getState) => {
    dispatch({type: DispatcherTypes.CREATE_TRANSLATION});

    const state: Store = getState();

    if (state.carriers.length === 0) dispatch(getCarriers());
    if (state.dealers.length === 0) dispatch(getDealers(state.filter.carrierId));

    const translation: Translation           = getState().popup.translation;
    const translationInput: TranslationInput = {
        carrierId              : translation.carrierId,
        dealerId               : translation.dealerId,
        [TableColumns.LANGUAGE]: translation[TableColumns.LANGUAGE],
        [TableColumns.LABEL]   : translation[TableColumns.LABEL],
        [TableColumns.VALUE]   : translation[TableColumns.VALUE]
    };
    DataService.getInstance()
        .createTranslation(translationInput)
        .then(data => {
            const location = data.headers.location ? data.headers.location : '';
            translation.id = location.length > 28 ? location.substring(28) : null;

            dispatch(addAlert(new Alert(AlertTypes.SUCCESS, appSettings.text.success_translation_created)));
            dispatch(getTranslations());
        })
        .catch(error => errorHandler(error, dispatch))
        .finally(() => dispatch(closePopup()));
};

export const editTranslation: any = (translationId: TranslationId, value: string) => dispatch => {
    dispatch({type: DispatcherTypes.EDIT_TRANSLATION});

    DataService.getInstance()
        .updateTranslation(translationId, value)
        .then(_ => {
            dispatch(updateTranslation(translationId, value));
        })
        .catch(error => errorHandler(error, dispatch))
        .finally(() => dispatch(closePopup()));

};

const errorHandler = (error, dispatch) => {
    switch (error.response.status) {
        case StatusCode.ALREADY_EXIST_409:
            dispatch(addAlert(new Alert(AlertTypes.DANGER, appSettings.text.error_already_exist)));
            break;
        case StatusCode.PERMISSION_401:
        case StatusCode.PERMISSION_403:
            dispatch(addAlert(new Alert(AlertTypes.DANGER, appSettings.text.error_permissions)));
            break;
        case StatusCode.SERVER_500:
        case StatusCode.SERVER_502:
        case StatusCode.SERVER_504:
            dispatch(addAlert(new Alert(AlertTypes.DANGER, appSettings.text.error_server)));
            break;
    }

    dispatch(setError(error));
};
