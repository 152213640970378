import React, { PureComponent } from 'react';
import TitledBlock              from '../../components/TitledBlock';
import { appSettings }          from '../../app.settings';
import InputText                from '../../components/InputText';
import { ReduxComponentProps }  from '../../types/reduxComponentProps';
import { connect }              from 'react-redux';
import { mapStateToProps }      from '../../helpers';
import { setTranslationValue }  from '../../ducks/popup';
import { TableColumns }         from '../../enums/tableColumns';
import { PopupType }            from '../../enums/popupType';

interface TranslationLabelProps extends ReduxComponentProps {
    isValid: (isValid: boolean) => void;
}

interface TranslationLabelState {
    isValid: boolean;
}

@connect(mapStateToProps)
class TranslationLabel extends PureComponent<TranslationLabelProps, TranslationLabelState> {

    constructor(props: TranslationLabelProps) {
        super(props);

        this.state = {
            isValid: true
        };

        this.labelChange = this.labelChange.bind(this);
    }

    labelChange(label: string): void {
        if (!label.includes(' ')) {
            this.props.dispatch(setTranslationValue(TableColumns.LABEL, label));
            this.setState({isValid: true});
            this.props.isValid(true);
        } else {
            this.setState({isValid: false});
            this.props.isValid(false);
        }
    }

    render(): React.ReactNode {
        return (
            <TitledBlock class="l-width--25 l-margin-right--25" title={appSettings.text.label}>
                <InputText
                    placeholder={appSettings.text.enter_label}
                    onChange={this.labelChange}
                    value={this.props.state.popup.translation && this.props.state.popup.translation[TableColumns.LABEL]}
                    disabled={this.props.state.popup.type === PopupType.EDIT}
                    valid={this.state.isValid}
                />
            </TitledBlock>
        );
    }
}

export default TranslationLabel;
