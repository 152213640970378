/**
 * Dropdown Component Class
 *
 */
import React, { PureComponent }                from 'react';
import './Dropdown.scss';
import icons                                   from '../../icons';
import { DropdownOption, DropdownOptionLabel } from '../../types/dropdownOption';
import Close                                   from '../Close';
import Search                                  from '../Search';
import { appSettings }                         from '../../app.settings';
import { Scrollbars }                          from 'react-custom-scrollbars';
import { ellipsis } from '../../helpers';

interface DropdownProps {
    heading: string;
    options: DropdownOption[];
    multipleSelection?: boolean;
    class?: string;
    search?: boolean;
    searchPlaceholder?: string;
    onSelect?: (selectedOption: DropdownOption) => void;
    selectedLabel?: DropdownOptionLabel;
    valid?: boolean;
    disabled?: boolean;
}

interface DropdownState {
    isDropdownOpen: boolean;
    selectedOption: DropdownOption;
    search: string;
}

class Dropdown extends PureComponent<DropdownProps, DropdownState> {

    nullSelectedOption: DropdownOption = {
        label  : null,
        heading: null
    };

    static defaultProps = {
        multipleSelection: false
    };

    constructor(props: DropdownProps) {
        super(props);

        this.state = {
            isDropdownOpen: false,
            selectedOption: this.props.selectedLabel
                            ? this.props.options.find(o => o.label === this.props.selectedLabel)
                            : null,
            search        : ''
        };

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.closeDropdown  = this.closeDropdown.bind(this);
        this.onSearch       = this.onSearch.bind(this);
        this.clear          = this.clear.bind(this);
    }

    // todo Update it !
    componentWillReceiveProps(nextProps: Readonly<DropdownProps>, nextContext: any): void {
        if (JSON.stringify(this.props.options) !== JSON.stringify(nextProps.options) && this.state.selectedOption
            || nextProps.options.length === 0 && this.state.selectedOption) {
            this.clear();
        }
    }

    /**
     * Toggle Dropdown
     */
    toggleDropdown(): void {
        if (this.props.disabled !== true) {
            this.setState({isDropdownOpen: !this.state.isDropdownOpen});
            this.setState({search: ''});
        }
    }

    /**
     * Close Dropdown
     */
    closeDropdown(): void {
        this.setState({isDropdownOpen: false});
        this.setState({search: ''});
    }

    /**
     * Select Option
     *
     * @param {DropdownOption} selectedOption
     */
    selectOption(selectedOption: DropdownOption): void {
        this.setState({selectedOption});
        if (this.props.onSelect) this.props.onSelect(selectedOption);
        this.closeDropdown();
    }

    /**
     * OnSearch Handler
     *
     * @param {string} search
     */
    onSearch(search: string): void {
        if (search !== this.state.search) this.setState({search: search.toLowerCase()});
    }

    /**
     * Clear Dropdown
     *
     * @param {React.MouseEvent<HTMLElement>} e
     */
    clear(e?: React.MouseEvent<HTMLElement>): void {
        if (this.props.disabled !== true) {
            if (e) e.stopPropagation();
            this.setState({selectedOption: null});
            if (this.props.onSelect) this.props.onSelect({...this.nullSelectedOption});
        }
    }

    /**
     * Render the Option
     *
     * @param {DropdownOption} option
     * @param {boolean} lastItem
     * @return {React.ReactNode}
     */
    renderOption(option: DropdownOption, lastItem: boolean): React.ReactNode {
        return (
            <li
                className={`c-dropdown__option ${lastItem ? 'c-dropdown__option--last' : ''}`}
                onClick={this.selectOption.bind(this, option)}
                key={option.label}
            >{ellipsis(option.heading, 28)}
            </li>
        );
    }

    /**
     * Render the Main Section
     *
     * @return {React.ReactNode}
     */
    renderMain(): React.ReactNode {
        const filteredOptions = this.props.options
            .filter((option: DropdownOption) => option.heading.toLowerCase().includes(this.state.search));

        return (
            <main className="c-dropdown__main">
                {
                    this.props.search && this.props.options.length !== 0 &&
                    <Search
                        class="c-dropdown__search"
                        placeholder={this.props.searchPlaceholder ? this.props.searchPlaceholder : appSettings.text.search}
                        onSearch={this.onSearch}
                    />
                }
                {
                    filteredOptions.length !== 0
                    ? <ul className="c-dropdown__options">
                        <Scrollbars autoHeight={true}>
                            {filteredOptions.map((option: DropdownOption, i: number) => this.renderOption(option, i + 1 === filteredOptions.length))}
                        </Scrollbars>
                    </ul>
                    : this.renderNoResults()
                }
            </main>
        );
    }

    /**
     * Render No Results Section
     *
     * @return {React.ReactNode}
     */
    renderNoResults(): React.ReactNode {
        return (
            <div className="c-dropdown__no-results">
                <h5 className="c-dropdown__no-results-heading">{appSettings.text.no_results}</h5>
                {
                    this.props.options.length !== 0 &&
                    <span
                        className="c-dropdown__no-results-subheading"
                    >{appSettings.text.for} "{this.state.search}"
                    </span>
                }
            </div>
        );
    }

    /**
     * Render
     *
     * @return {React.ReactNode}
     */
    render(): React.ReactNode {
        return (
            <Close isOpen={this.state.isDropdownOpen} closeChange={this.closeDropdown}>
                <div
                    className={`c-dropdown ${this.props.disabled === true ? 'c-dropdown--disabled' : ''}
                    ${this.props.valid === false ? 'c-dropdown--danger' : ''}
                    ${this.state.isDropdownOpen ? 'c-dropdown--open' : ''}
                    ${this.props.class ? this.props.class : ''}`}
                >

                    <header
                        className={`c-dropdown__header ${this.state.selectedOption ? 'c-dropdown__header--selected' : ''}`}
                        onClick={this.toggleDropdown}
                    >
                        {this.state.selectedOption ? ellipsis(this.state.selectedOption.heading, 23) : ellipsis(this.props.heading, 23)}
                        <i
                            className="c-dropdown__icon--arrow"
                        >{this.state.isDropdownOpen ? icons.ARROW_UP : icons.ARROW_DOWN}
                        </i>
                        {
                            this.state.selectedOption
                            && <i
                                className="c-dropdown__icon--clear"
                                onClick={this.clear}
                            >{icons.CLOSE}
                            </i>
                        }
                    </header>

                    {this.state.isDropdownOpen && this.renderMain()}

                </div>
            </Close>
        );
    }
}

export default Dropdown;
