import React, { PureComponent } from 'react';
import './Tag.scss';
import { appSettings }          from '../../app.settings';
import Popup                    from '../Popup';
import icons                    from '../../icons/icons';
import { ElementPosition }      from '../../types/elementPosition';
import Close                    from '../Close';

interface TagProps {
    title?: string;
    value?: string;
    custom?: boolean;
    class?: string;
    onSelect?: (value: string) => void;
}

interface TagState {
    isPopupOpen: boolean;
    additionalPopupClass: string;
}

class Tag extends PureComponent<TagProps, TagState> {

    static defaultProps = {
        custom: false
    };

    private tagRef: React.RefObject<HTMLDivElement>;
    private popupRef: React.RefObject<any>;
    position: ElementPosition;

    constructor(props: TagProps) {
        super(props);

        this.state = {
            isPopupOpen         : false,
            additionalPopupClass: ''
        };

        this.tagRef   = React.createRef();
        this.popupRef = React.createRef();

        this.closePopup      = this.closePopup.bind(this);
        this.tagClickHandler = this.tagClickHandler.bind(this);
        this.addCustomTag    = this.addCustomTag.bind(this);
        this.submitPopup     = this.submitPopup.bind(this);
    }

    componentDidMount(): void {
        this.setTagPosition();
    }

    setTagPosition(): void {
        if (this.tagRef && this.tagRef.current) {
            const clientRect: DOMRect | ClientRect = this.tagRef.current.getBoundingClientRect();

            this.position = {
                x: (clientRect as DOMRect).x,
                y: (clientRect as DOMRect).y
            };
        }
    }

    popupPositionHandler(): void {
        if (this.popupRef.current.clientHeight > this.position.x) {
            this.setState({
                additionalPopupClass: 'c-tag__popup--down'
            });
        }
    }

    async openPopup(): Promise<void> {
        await this.setState({isPopupOpen: true});
        this.popupPositionHandler();
    }

    closePopup(): void {
        this.setState({isPopupOpen: false});
    }

    tagClickHandler(): void {
        if (this.props.custom) {
            this.addCustomTag();
        } else {
            if (this.props.onSelect) this.props.onSelect(this.props.value);
        }
    }

    addCustomTag(): void {
        this.openPopup();
    }

    submitPopup(): void {
        console.log('submit tag popup');
    }

    render(): React.ReactNode {
        return (
            <Close isOpen={this.state.isPopupOpen} closeChange={this.closePopup}>
                <div
                    ref={this.tagRef}
                    className={`c-tag c-tag--${'blue'} ${this.props.class ? this.props.class : ''}`}
                >
                    <span
                        className="c-tag__title"
                        onClick={this.tagClickHandler}
                    >{this.props.custom ? appSettings.text.custom : this.props.title}
                    </span>
                    {
                        this.props.custom &&
                        <div className="c-tag__button" onClick={this.tagClickHandler}>
                            <i className="c-tag__button-icon"/>
                        </div>
                    }
                    {
                        this.props.custom && this.state.isPopupOpen
                        &&
                        <Popup
                            reference={this.popupRef}
                            class={`c-tag__popup ${this.state.additionalPopupClass}`}
                            title={appSettings.text.global_level_translation}
                            titleIcon={icons.ADD}
                            onClose={this.closePopup}
                            onCancel={this.closePopup}
                            onSubmit={this.submitPopup}
                        >Popup
                        </Popup>
                    }
                </div>
            </Close>
        );
    }
}

export default Tag;
