/**
 * Application's Environment
 */
import { EnvironmentMode } from './enums/environmentMode';

const envFile = require('../env.json');

const env          = process.env.UI_ENV || EnvironmentMode.LOCAL;
const envVariables = envFile[env];

const environment = {
    mode      : env,
    apiUrl    : envVariables.REACT_APP_TRANSLATION_API_URL,
    graphQLUrl: envVariables.REACT_APP_CARRIER_DEALER_API_URL,
    webAppHost: envVariables.WEBAPP_HOST
};

export default environment;
