import React       from 'react';
import './App.scss';
import Header      from '../components/Header';
import SidebarNav  from '../components/SidebarNav';
import Table       from '../containers/table/Table';
import Filters     from '../components/Filters';
import ActionPopup from '../containers/createAndEdit/ActionPopup';
import Alerts      from '../components/Alerts/Alerts';

const App = () => {
    return (
        <div className="c-app">

            <SidebarNav/>

            <main className="c-app__main">

                <Header/>
                <Alerts/>
                <Filters/>
                <Table/>
                <ActionPopup/>

            </main>
        </div>
    );
};

export default App;
