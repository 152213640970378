import React, { PureComponent }     from 'react';
import { ReduxComponentProps }      from '../../types/reduxComponentProps';
import { appSettings }              from '../../app.settings';
import TitledBlock                  from '../../components/TitledBlock';
import Search                       from '../../components/Search';
import { debounce, mapStateToProps} from '../../helpers';
import { connect }                  from 'react-redux';
import { setFilterOptions }         from '../../ducks/filter';

interface SearchFilterProps extends ReduxComponentProps {
}

interface SearchFilterState {
    searchSpinner: boolean;
}

@connect(mapStateToProps)
class SearchFilter extends PureComponent<SearchFilterProps, SearchFilterState> {

    constructor(props) {
        super(props);

        this.state = {
            searchSpinner: false
        };

        this.onSearch = this.onSearch.bind(this);
    }

    /**
     * Search Field Spinner With Debounce
     * @type {() => void}
     */
    spinnerWithDebounce = debounce(() => this.setState({searchSpinner: true}), 200);

    /**
     * Apply Filters Handler With Debounce
     * @type {() => void}
     */
    applyWithDebounce = debounce(() => this.apply(), 1000);

    /**
     * OnSearch Event Handler
     *
     * @param {string} search
     */
    onSearch(search: string): void {
        this.props.dispatch(setFilterOptions({
            ...this.props.state.filter,
            search: search ? search : ''
        }));
        this.spinnerWithDebounce();
        this.applyWithDebounce();
    }

    /**
     * Apply Filters Handler
     */
    apply(): void {
        this.setState({searchSpinner: false});
    }

    render(): React.ReactNode {

        return (
            <TitledBlock class="c-filters__filter" title={appSettings.text.search}>
                <Search
                    placeholder={appSettings.text.search}
                    spinner={this.state.searchSpinner}
                    onSearch={this.onSearch}
                    value={this.props.state.filter.search}
                />
            </TitledBlock>
        );
    }

}

export default SearchFilter;
