import React, { PureComponent } from 'react';
import { appSettings }          from '../../app.settings';
import { PopupType }                                               from '../../enums/popupType';
import Dropdown                                                    from '../../components/Dropdown';
import TitledBlock                                                 from '../../components/TitledBlock';
import { DropdownOption }                                          from '../../types/dropdownOption';
import { connect }                                                 from 'react-redux';
import { mapStateToProps }                                         from '../../helpers';
import { ReduxComponentProps }                                     from '../../types/reduxComponentProps';
import { clearPopupDealers, setPopupDealers, setTranslationValue } from '../../ducks/popup';
import { getDealers }                                              from '../../ducks/dispatchers';

interface TranslationCarrierProps extends ReduxComponentProps {
}

@connect(mapStateToProps)
class TranslationCarrier extends PureComponent<TranslationCarrierProps> {

    constructor(props: TranslationCarrierProps) {
        super(props);

        this.carrierChange = this.carrierChange.bind(this);
    }

    carrierChange(carrier: DropdownOption): void {
        this.props.dispatch(setTranslationValue('carrierId', carrier.label));
        this.props.dispatch(setTranslationValue('dealerId', null));

        if (carrier.label !== null) {
            this.props.dispatch(getDealers(carrier.label, setPopupDealers));
        } else {
            this.props.dispatch(clearPopupDealers());
        }
    }

    render(): React.ReactNode {
        const carrierDropdownOptions: DropdownOption[] = [...this.props.state.carriers]
            .map(d => ({label: d.id, heading: d.name}));

        return (
            <TitledBlock class="l-width--25 l-margin-right--25" title={appSettings.text.carrier}>
                <Dropdown
                    search={true}
                    searchPlaceholder={appSettings.text.search_a_carrier}
                    heading={appSettings.text.select_carrier}
                    options={carrierDropdownOptions}
                    onSelect={this.carrierChange}
                    selectedLabel={this.props.state.popup.translation && this.props.state.popup.translation.carrierId}
                    disabled={this.props.state.popup.type === PopupType.EDIT}
                />
            </TitledBlock>
        );
    }
}

export default TranslationCarrier;
