import React, { PureComponent }                from 'react';
import { ButtonColor, ButtonSize, ButtonType } from './button.enums';
import './Button.scss';

interface ButtonProps extends ButtonDefaultProps {
    children: string;
    onClick?: () => void;
    class?: string;
}

interface ButtonDefaultProps {
    size?: string;
    type?: string;
    color?: string;
    disabled?: boolean;
}

class Button extends PureComponent<ButtonProps> {

    baseClassName = 'e-button';

    static defaultProps: ButtonDefaultProps = {
        size : ButtonSize.LARGE,
        type : ButtonType.REGULAR,
        color: ButtonColor.PRIMARY
    };

    getModifierClasses(): string {
        return `${this.baseClassName}--${this.props.size} ${this.baseClassName}--${this.props.type}-${this.props.color}`
    }

    render(): React.ReactNode {
        return (
            <button
                disabled={this.props.disabled ? this.props.disabled : false}
                className={`${this.baseClassName} ${this.getModifierClasses()} ${this.props.class ? this.props.class : ''}`}
                onClick={this.props.onClick}
            >{this.props.children}
            </button>
        );
    }
}

export default Button;
