/**
 * Alerts Wrapper Component Class
 */
import React, { Component }    from 'react';
import { Store }               from '../../types/store';
import { connect }             from 'react-redux';
import { AlertItem }           from './alertItem';
import Alert                   from './Alert';
import { ReduxComponentProps } from '../../types/reduxComponentProps';

interface AlertsComponentProps extends ReduxComponentProps {
}

class Alerts extends Component<AlertsComponentProps> {

    render() {
        return (
            <>
                {this.props.state.alerts.map((alert: AlertItem) => <Alert
                    key={alert.id}
                    id={alert.id}
                    type={alert.type}
                    message={alert.message}
                />)}
            </>
        );
    }
}

const mapStateToProps = (state: Store) => ({state});

export default connect(mapStateToProps)(Alerts);
