import React, { PureComponent }   from 'react';
import './Popup.scss';
import Button                     from '../Button';
import { appSettings }            from '../../app.settings';
import { ButtonSize, ButtonType } from '../Button/button.enums';
import icons                      from '../../icons';

interface PopupProps {
    class?: string;
    title: string;
    titleIcon?: React.ReactNode;
    submitBtnText?: string;
    cancelBtn?: boolean;
    cancelBtnText?: string;
    onClose?: () => void;
    onCancel?: () => void;
    reference?: any;
    onSubmit?: () => void;
    shadowBgCenter?: boolean;
    children: React.ReactNode;
    width?: string;
    disabledSubmit?: boolean;
    renderFooter?: boolean;
}

class Popup extends PureComponent<PopupProps> {

    static defaultProps = {
        submitBtnText: appSettings.text.save,
        cancelBtn    : true,
        cancelBtnText: appSettings.text.cancel,
        shadowBgCenter: false,
        renderFooter: true
    };

    renderHeader(): React.ReactNode {
        return (
            <header className="c-popup__header">
                <h3 className="c-popup__header-title">
                    {
                        this.props.titleIcon && <i className="c-popup__header-title-icon">{this.props.titleIcon}</i>
                    }
                    {this.props.title}
                </h3>

                <i
                    className="c-popup__close-icon"
                    onClick={this.props.onClose}
                >{icons.CLOSE}
                </i>
            </header>
        );
    }

    renderFooter(): React.ReactNode {
        return (
            <footer className="c-popup__footer">
                <Button
                    class="l-margin-right--10"
                    size={ButtonSize.SMALL}
                    type={ButtonType.DEEMPHASIZED}
                    onClick={this.props.onCancel}
                >{appSettings.text.cancel}
                </Button>
                <Button
                    size={ButtonSize.SMALL}
                    onClick={this.props.onSubmit}
                    disabled={this.props.disabledSubmit ? this.props.disabledSubmit : false}
                >{this.props.submitBtnText}
                </Button>
            </footer>
        );
    }

    render(): React.ReactNode {
        return (
            <div
                className={`c-popup ${this.props.class ? this.props.class : ''} ${this.props.shadowBgCenter ? 'c-popup--shadow-center' : ''}`}
                ref={this.props.reference}
            >
                <div className="c-popup__wrapper" style={this.props.width && {width: this.props.width}}>

                {/*Header*/}
                {this.renderHeader()}
                {/*-/- Header*/}

                {/*Main*/}
                <main className="c-popup__main">
                    {this.props.children}
                </main>
                {/*-/- Main*/}

                {/*Footer*/}
                {this.props.renderFooter && this.renderFooter()}
                {/*-/- Footer*/}

                </div>

            </div>
        );
    }
}

export default Popup;
