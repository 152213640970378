import React, { PureComponent } from 'react';
import { appSettings }          from '../../app.settings';
import TitledBlock              from '../../components/TitledBlock';
import Dropdown                 from '../../components/Dropdown';
import { connect }              from 'react-redux';
import { mapStateToProps }      from '../../helpers';
import { DropdownOption }       from '../../types/dropdownOption';
import { ReduxComponentProps }  from '../../types/reduxComponentProps';
import { TableColumns }         from '../../enums/tableColumns';
import { setTranslationValue }  from '../../ducks/popup';
import { PopupType }            from '../../enums/popupType';

interface TranslationLanguageProps extends ReduxComponentProps {}

@connect(mapStateToProps)
class TranslationLanguage extends PureComponent<TranslationLanguageProps> {

    constructor(props: TranslationLanguageProps) {
        super(props);

        this.languageChange = this.languageChange.bind(this);
    }

    languageChange(language: DropdownOption): void {
        this.props.dispatch(setTranslationValue(TableColumns.LANGUAGE, language.label));
    }

    render(): React.ReactNode {
        return (
            <TitledBlock class="l-width--25 l-margin-right--25" title={appSettings.text.language}>
                <Dropdown
                    search={true}
                    searchPlaceholder={appSettings.text.search_language}
                    heading={appSettings.text.select_language}
                    options={appSettings.languageDropdownOptions}
                    onSelect={this.languageChange}
                    selectedLabel={this.props.state.popup.translation && this.props.state.popup.translation[TableColumns.LANGUAGE]}
                    disabled={this.props.state.popup.type === PopupType.EDIT}
                />
            </TitledBlock>
        );
    }
}

export default TranslationLanguage;
