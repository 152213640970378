import React, { PureComponent } from 'react';
import { appSettings }          from '../../app.settings';
import Dropdown                 from '../../components/Dropdown';
import TitledBlock              from '../../components/TitledBlock';
import { ReduxComponentProps }  from '../../types/reduxComponentProps';
import { connect }              from 'react-redux';
import { mapStateToProps }      from '../../helpers';
import { DropdownOption }       from '../../types/dropdownOption';
import { setFilterOptions }     from '../../ducks/filter';

interface DealerFilterProps extends ReduxComponentProps {}

@connect(mapStateToProps)
class DealerFilter extends PureComponent<DealerFilterProps> {

    constructor(props: DealerFilterProps) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
    }

    onSelect(dealer: DropdownOption): void {
        this.props.dispatch(setFilterOptions({
            ...this.props.state.filter,
            dealerId: dealer.label as number
        }));
    }

    render(): React.ReactNode {
        const options: DropdownOption[] = this.props.state.dealers.map(d => ({label: d.id, heading: d.name}));

        return (
            <TitledBlock class="c-filters__filter" title={appSettings.text.dealer}>
                <Dropdown
                    search={true}
                    searchPlaceholder={appSettings.text.search_a_dealer}
                    heading={appSettings.text.select_dealer}
                    options={options}
                    onSelect={this.onSelect}
                    disabled={!this.props.state.filter.carrierId}
                />
            </TitledBlock>
        );
    }
}

export default DealerFilter;
