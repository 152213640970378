/**
 * Map State To Props Method
 *
 * @param {Store} state
 * @return {{state: Store}}
 */
import { Store } from './types/store';

export const mapStateToProps = (state: Store) => ({state});

export const getQueryParams = (obj: { [key: string]: any }) => {
    const q = [];
    for (const p in obj) {
        if (obj.hasOwnProperty(p) && obj[p] !== null && obj[p] !== undefined) {
            const escaped = escape(obj[p]);
            q.push(`${p}=${escaped}`);
        }
    }
    return q.join('&');
};

/**
 * Debounce Method
 *
 * @param {() => void} func
 * @param {number} delay
 * @return {() => void}
 */
export const debounce = (func: () => void, delay: number): () => void => {
    let inDebounce;
    return function () {
        const context = this;
        const args    = arguments;
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
};

/**
 * Ellipsis Method
 *
 * @param {string} text
 * @param {number} size
 * @returns {string}
 */
export const ellipsis = (text: string, size: number): string => text && text.length > size ? text.slice(0, size) + '...' : text;
