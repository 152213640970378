import { combineReducers } from 'redux';

import loadingReducer      from './loading';
import popupReducer        from './popup';
import dealersReducer      from './dealers';
import carriersReducer     from './carriers';
import filterReducer       from './filter';
import errorsReducer       from './errors';
import translationsReducer from './translations';
import alertsReducer       from './alerts';
import allDealersReducer   from './allDealers';

const rootReducer = combineReducers({
    alerts: alertsReducer,
    errors: errorsReducer,
    loading: loadingReducer,
    popup: popupReducer,
    dealers: dealersReducer,
    carriers: carriersReducer,
    filter: filterReducer,
    translations: translationsReducer,
    allDealers: allDealersReducer
});

export default rootReducer;
