import React, { PureComponent } from 'react';
import { appSettings }          from '../../app.settings';
import TitledBlock             from '../../components/TitledBlock';
import Dropdown                from '../../components/Dropdown';
import { ReduxComponentProps } from '../../types/reduxComponentProps';
import { connect }             from 'react-redux';
import { mapStateToProps }     from '../../helpers';
import { DropdownOption }      from '../../types/dropdownOption';
import { setTranslationValue } from '../../ducks/popup';
import { PopupType }           from '../../enums/popupType';

interface DealerProps extends ReduxComponentProps {}

@connect(mapStateToProps)
class TranslationDealer extends PureComponent<DealerProps> {

    constructor(props: DealerProps) {
        super(props);

        this.dealerChange = this.dealerChange.bind(this);
    }

    dealerChange(dealer: DropdownOption): void {
        this.props.dispatch(setTranslationValue('dealerId', dealer.label));
    }

    render(): React.ReactNode {
        const dealersDropdownOptions: DropdownOption[] = [...this.props.state.popup.dealers]
            .map(d => ({label: d.id, heading: d.name}));

        return (
            <TitledBlock class="l-width--25 l-margin-right--25" title={appSettings.text.dealer}>
                <Dropdown
                    search={true}
                    searchPlaceholder={appSettings.text.search_a_dealer}
                    heading={appSettings.text.select_dealer}
                    options={dealersDropdownOptions}
                    onSelect={this.dealerChange}
                    selectedLabel={this.props.state.popup.translation && this.props.state.popup.translation.dealerId}
                    disabled={this.props.state.popup.type === PopupType.EDIT || !(this.props.state.popup.translation && this.props.state.popup.translation.carrierId)}
                />
            </TitledBlock>
        );
    }
}

export default TranslationDealer;
