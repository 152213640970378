import React                            from 'react';
import ReactDOM                         from 'react-dom';
import App                              from './App';
import './styles/screen.scss';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools }          from 'redux-devtools-extension';
import thunk                            from 'redux-thunk';
import { Provider }                     from 'react-redux';
import * as serviceWorker               from './serviceWorker';
import rootReducer                      from './ducks';
import { ApolloProvider }               from 'react-apollo';
import DataService                      from './services/dataService';

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

const client = DataService.getInstance().getClient();

ReactDOM.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <App/>
        </Provider>
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();





