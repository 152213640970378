import React, { PureComponent } from 'react';
import { appSettings }          from '../../app.settings';
import { TableColumns }         from '../../enums/tableColumns';
import { setTranslationValue }  from '../../ducks/popup';
import TitledBlock              from '../../components/TitledBlock';
import { connect }              from 'react-redux';
import { mapStateToProps }      from '../../helpers';
import { ReduxComponentProps }  from '../../types/reduxComponentProps';
import Textarea                 from '../../components/Textarea';

interface TranslationValueProps extends ReduxComponentProps {
}

@connect(mapStateToProps)
class TranslationValue extends PureComponent<TranslationValueProps> {

    constructor(props: TranslationValueProps) {
        super(props);

        this.valueChange = this.valueChange.bind(this);
    }

    valueChange(value: string): void {
        this.props.dispatch(setTranslationValue(TableColumns.VALUE, value));
    }

    render(): React.ReactNode {
        return (
            <TitledBlock class="l-width--100" title={appSettings.text.value}>
                <Textarea
                    placeholder={appSettings.text.enter_value}
                    onChange={this.valueChange}
                    value={this.props.state.popup.translation && this.props.state.popup.translation[TableColumns.VALUE]}
                />
            </TitledBlock>
        );
    }
}

export default TranslationValue;
