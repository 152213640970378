import { Carrier }  from '../types/carrier';
import { Action }   from '../types/action';

enum CarriersActionTypes {
    SET = 'translation-ui/carriers/SET',
    CLEAR = 'translation-ui/carriers/CLEAR'
}

/**
 * Set Carriers
 *
 * @param {Carrier[]} carriers
 * @return {{data: Carrier[]; type: CarriersActionTypes}}
 */
export const setCarriers = (carriers: Carrier[]) => ({
    type: CarriersActionTypes.SET,
    data: carriers
});

/**
 * Reducer
 *
 * @param {Carrier[]} state
 * @param {Action<CarriersActionTypes, Carrier[]>} action
 * @return {Carrier[]}
 */
const carriersReducer = (state: Carrier[] = [], action: Action<CarriersActionTypes, Carrier[]>) => {
    switch (action.type) {
        case CarriersActionTypes.SET:
            return [...action.data];
        default:
            return state;
    }
};

export default carriersReducer;
