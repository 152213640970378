import DataService  from '../services/dataService';
import { Dealer }   from '../types/dealer';
import { setError } from './errors';
import { Action }   from '../types/action';

enum AllDealersActionTypes {
    GET = 'translation-ui/allDealers/GET',
    SET = 'translation-ui/allDealers/SET'
}

export const getAllDealers: any = () => dispatch => {
    dispatch({type: AllDealersActionTypes.GET});

    DataService.getInstance()
        .getAllDealers()
        .then((d: Dealer[]) => dispatch(setAllDealers(d)))
        .catch(e => dispatch(setError(e)));
};

export const setAllDealers = (dealers: Dealer[]) => ({
    type: AllDealersActionTypes.SET,
    data: dealers
});

const allDealersReducer = (state: Dealer[] = [], action: Action<AllDealersActionTypes, Dealer[]>) => {
    switch (action.type) {
        case AllDealersActionTypes.SET:
            return [...action.data];
        default:
            return state;
    }
};

export default allDealersReducer;
