import { Dealer }   from '../types/dealer';
import { Action }   from '../types/action';

enum DealersActionTypes {
    SET = 'translation-ui/dealers/SET',
    CLEAR = 'translation-ui/dealers/CLEAR'
}

/**
 * Set Dealers
 *
 * @param {Dealer[]} dealers
 * @return {{data: Dealer[]; type: DealersActionTypes}}
 */
export const setDealers = (dealers: Dealer[]) => ({
    type: DealersActionTypes.SET,
    data: dealers
});

export const clearDealers = () => ({
    type: DealersActionTypes.CLEAR
});

/**
 * Reducer
 *
 * @param {Dealer[]} state
 * @param {Action<DealersActionTypes, Dealer[]>} action
 * @return {Dealer[]}
 */
const dealersReducer = (state: Dealer[] = [], action: Action<DealersActionTypes, Dealer[]>) => {
    switch (action.type) {
        case DealersActionTypes.SET:
            return [...action.data];
        case DealersActionTypes.CLEAR:
            return [];
        default:
            return state;
    }
};

export default dealersReducer;
