import React, { PureComponent }    from 'react';
import { appSettings }             from '../../app.settings';
import TitledBlock                  from '../../components/TitledBlock';
import Dropdown                     from '../../components/Dropdown';
import { connect }                  from 'react-redux';
import { mapStateToProps }          from '../../helpers';
import { ReduxComponentProps }      from '../../types/reduxComponentProps';
import { DropdownOption }           from '../../types/dropdownOption';
import { setFilterOptions }         from '../../ducks/filter';
import { getCarriers, getDealers }  from '../../ducks/dispatchers';
import { clearDealers, setDealers } from '../../ducks/dealers';
import { clearFilter}              from '../../ducks/filter';

interface CarrierFilterProps extends ReduxComponentProps {
}

@connect(mapStateToProps)
class CarrierFilter extends PureComponent<CarrierFilterProps> {

    constructor(props: CarrierFilterProps) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount(): void {
        this.props.dispatch(getCarriers());
    }

    onSelect(carrier: DropdownOption): void {

        this.props.dispatch(setFilterOptions({
            dealerId : null,
            carrierId: carrier.label as number,
            search: ''
        }));

        // get dealers
        if (carrier.label !== null) {
            this.props.dispatch(getDealers(carrier.label, setDealers));
        } else {
            this.props.dispatch(clearDealers());
            this.props.dispatch(clearFilter({}));
        }
    }

    render(): React.ReactNode {
        const options: DropdownOption[] = this.props.state.carriers.map(c => ({label: c.id, heading: c.name}));

        return (
            <TitledBlock class="c-filters__filter" title={appSettings.text.carrier}>
                <Dropdown
                    search={true}
                    searchPlaceholder={appSettings.text.search_a_carrier}
                    heading={appSettings.text.select_carrier}
                    options={options}
                    onSelect={this.onSelect}
                />
            </TitledBlock>
        );
    }
}

export default CarrierFilter;
