import { AlertItem } from '../components/Alerts/alertItem';

export enum AlertsActionTypes {
    ADD_ALERT    = 'ADD_ALERT',
    REMOVE_ALERT = 'REMOVE_ALERT',
    REMOVE_ALL_ALERTS = 'REMOVE_ALL_ALERTS'
}

/**
 * Add AlertItem to the List
 *
 * @param data - alert
 */
export const addAlert = (data: AlertItem) => (
    {
        type: AlertsActionTypes.ADD_ALERT,
        data
    }
);

/**
 * Remove AlertItem from the List
 *
 * @param data - alert
 */
export const removeAlert = (data: AlertItem) => (
    {
        type: AlertsActionTypes.REMOVE_ALERT,
        data
    }
);

/**
 * Remove All Alerts
 *
 * @return {{type: AlertsActionTypes}}
 */
export const removeAllAlerts = () => (
    {
        type: AlertsActionTypes.REMOVE_ALL_ALERTS
    }
);




const alertsReducer = (state: AlertItem[] = [], action: { type: string, data: AlertItem }) => {
    switch (action.type) {
        case AlertsActionTypes.ADD_ALERT:
            return [].concat(action.data); // NOTE: Return always 1 item in the alerts array
        case AlertsActionTypes.REMOVE_ALERT: {
            const i = state.findIndex(a => a.id === action.data.id);
            return [
                ...state.slice(0, i),
                ...state.slice(i + 1)
            ];
        }
        case AlertsActionTypes.REMOVE_ALL_ALERTS:
            return [];
        default:
            return state;
    }
};

export default alertsReducer;
