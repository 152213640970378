import React, { PureComponent }       from 'react';
import { connect }                    from 'react-redux';
import { ellipsis, mapStateToProps }  from '../../helpers';
import { ReduxComponentProps }        from '../../types/reduxComponentProps';
import TableNoData                    from './TableNoData';
import { AutoSizer, MultiGrid }       from 'react-virtualized';
import { appSettings }                from '../../app.settings';
import TableHeaderCell                from './TableHeaderCell';
import Button                         from '../../components/Button';
import { ButtonType }                 from '../../components/Button/button.enums';
import { Translation, TranslationId } from '../../types/translation';
import Popup                          from '../../components/Popup';
import { TableColumns }               from '../../enums/tableColumns';
import { deleteTranslation }          from '../../ducks/dispatchers';
import '../../styles/_components.table.scss';
import { openPopup }                  from '../../ducks/popup';
import { PopupType }                  from '../../enums/popupType';
import Tag                            from '../../components/Tag';
import { General }                    from '../../enums/general';
import { getAllDealers }              from '../../ducks/allDealers';

interface TableProps extends ReduxComponentProps {
}

interface TableState {
    hoveredRow: number;
    isDeleteConfirmationPopupOpen: boolean;
    translationToDeleteId: TranslationId;
}

@connect(mapStateToProps)
class Table extends PureComponent<TableProps, TableState> {

    headerHeight: number = 33;
    rowHeight: number    = 72;
    tableHeight: number  = this.rowHeight * 8;
    tableColumns         = appSettings.tableColumns;

    constructor(props: TableProps) {
        super(props);

        this.state = {
            hoveredRow                   : null,
            isDeleteConfirmationPopupOpen: false,
            translationToDeleteId        : null
        };

        this.mouseEnter                = this.mouseEnter.bind(this);
        this.mouseLeave                = this.mouseLeave.bind(this);
        this.cancelTranslationDeletion = this.cancelTranslationDeletion.bind(this);
        this.deleteTranslation         = this.deleteTranslation.bind(this);
        this.cellRenderer              = this.cellRenderer.bind(this);
        this.getColumnHeight           = this.getColumnHeight.bind(this);

    }

    componentDidMount(): void {
        this.props.dispatch(getAllDealers());
    }

    /**
     * Table Row Mouse Enter Event Handler
     *
     * @param {number} rowIndex
     */
    mouseEnter(rowIndex: number): void {
        this.setState({hoveredRow: rowIndex});
    }

    /**
     * Table Row Mouse Leave Event Handler
     */
    mouseLeave(): void {
        this.setState({hoveredRow: null});
    }

    /**
     * Edit Translation
     *
     * @param {Translation} translation
     */
    editTranslation(translation: Translation): void {
        this.props.dispatch(openPopup(PopupType.EDIT, translation));
    }

    /**
     * Delete Translation Handler
     */
    deleteTranslation(): void {
        this.setState({isDeleteConfirmationPopupOpen: false});
        this.props.dispatch(deleteTranslation(this.state.translationToDeleteId));
    }

    /**
     * Show the confirmation for deletion
     *
     * @param {string} translationToDeleteId
     */
    showDeleteTranslationConfirmation(translationToDeleteId: string): void {
        this.setState({
            translationToDeleteId,
            isDeleteConfirmationPopupOpen: true
        });
    }

    /**
     * Cancel the translation deletion handler
     */
    cancelTranslationDeletion(): void {
        this.setState({
            translationToDeleteId        : null,
            isDeleteConfirmationPopupOpen: false
        });
    }

    /**
     * Get Column Width Method
     *
     * @param {number} width
     * @param {any} index
     * @return {number}
     */
    getColumnWidth(width: number, {index}): number {
        const k = width / this.tableColumns.reduce((prevValue, tc) => tc.width + prevValue, 0);
        return this.tableColumns[index].width * k;
    }

    /**
     * Get Column Height Method
     *
     * @param {any} index
     * @return {number}
     */
    getColumnHeight({index}): number {
        return index === 0 ? this.headerHeight : this.rowHeight;
    }

    /**
     * RENDERS
     * =============================================================================================================
     */

    /**
     * Render Table Cell Value
     *
     * @param {number} columnIndex
     * @param {number} rowIndex
     * @return {number | string | React.ReactNode}
     */
    renderTableCellValue(columnIndex: number, rowIndex: number): number | string | React.ReactNode {
        if (this.tableColumns[columnIndex].label === TableColumns.CARRIER
            && !this.props.state.translations[rowIndex - 1][this.tableColumns[columnIndex].label]) return (
            <Tag title={General.GLOBAL}/>);
        if (this.tableColumns[columnIndex].label === TableColumns.DEALER
            && !this.props.state.translations[rowIndex - 1][this.tableColumns[columnIndex].label]) return (
            <Tag title={General.GLOBAL}/>);
        if (this.tableColumns[columnIndex].label === TableColumns.ROW_NUMBER) return rowIndex;
        if (this.tableColumns[columnIndex].label === TableColumns.ACTIONS) return this.renderActions(rowIndex);
        if (this.tableColumns[columnIndex].label === TableColumns.LABEL) return ellipsis(this.props.state.translations[rowIndex - 1][this.tableColumns[columnIndex].label], 30);
        if (this.tableColumns[columnIndex].label === TableColumns.VALUE) return ellipsis(this.props.state.translations[rowIndex - 1][this.tableColumns[columnIndex].label], 150);

        return this.props.state.translations[rowIndex - 1][this.tableColumns[columnIndex].label];
    }

    /**
     * Render Actions for Table Cell
     *
     * @param {number} rowIndex
     * @return {React.ReactNode}
     */
    renderActions(rowIndex: number): React.ReactNode {
        return (
            <div className="c-table__actions">
                <Button
                    type={ButtonType.DEEMPHASIZED}
                    onClick={this.editTranslation.bind(this, this.props.state.translations[rowIndex - 1])}
                >{appSettings.text.edit}
                </Button>
                <Button
                    type={ButtonType.DEEMPHASIZED}
                    onClick={this.showDeleteTranslationConfirmation.bind(this, this.props.state.translations[rowIndex - 1].id)}
                >{appSettings.text.delete}
                </Button>
            </div>
        );
    }

    /**
     * Cell Renderer
     *
     * @param {any} columnIndex
     * @param {any} key
     * @param {any} rowIndex
     * @param {any} style
     * @return {React.ReactNode}
     */
    cellRenderer({columnIndex, key, rowIndex, style}): React.ReactNode {
        // header
        if (rowIndex === 0) return (
            <TableHeaderCell
                key={key}
                columnIndex={columnIndex}
                columnLabel={this.tableColumns[columnIndex].label}
                style={style}
                heading={this.tableColumns[columnIndex].heading}
            />
        );

        // default
        return (
            <span
                className={`c-table__row-cell
                ${rowIndex % 2 === 0 ? 'c-table__row-cell--odd' : 'c-table__row-cell--even'}
                ${
                    this.tableColumns[columnIndex].label === TableColumns.ROW_NUMBER
                    || this.tableColumns[columnIndex].label === TableColumns.ACTIONS
                    ? 'c-table__row-cell--center'
                    : ''
                    }
                ${this.state.hoveredRow === rowIndex && columnIndex === this.tableColumns.length - 1 ? 'c-table__row-cell--hovered' : ''}`}
                key={key}
                style={style}
                onMouseEnter={this.mouseEnter.bind(this, rowIndex)}
                onMouseLeave={this.mouseLeave}
            >{this.renderTableCellValue(columnIndex, rowIndex)}
            </span>
        );
    }

    /**
     * Render Delete Confirmation Popup
     *
     * @return {React.ReactNode}
     */
    renderDeleteConfirmationPopup(): React.ReactNode {
        return (
            <Popup
                title={appSettings.text.confirmation}
                shadowBgCenter={true}
                onClose={this.cancelTranslationDeletion}
                onCancel={this.cancelTranslationDeletion}
                onSubmit={this.deleteTranslation}
                submitBtnText={appSettings.text.delete}
            ><h1 className="e-heading e-heading--h3">{appSettings.text.are_you_sure_you_want_to_remove}</h1>
            </Popup>
        );
    }

    /**
     * Render Table
     *
     * @return {React.ReactNode}
     */
    renderTable(): React.ReactNode {
        return (
            <AutoSizer disableHeight={true}>
                {({width}) => {
                    return (
                        <MultiGrid
                            cellRenderer={this.cellRenderer}
                            columnWidth={this.getColumnWidth.bind(this, width)}
                            columnCount={this.tableColumns.length}
                            fixedRowCount={1}
                            enableFixedColumnScroll={false}
                            enableFixedRowScroll={false}
                            hideTopRightGridScrollbar={true}
                            hideBottomLeftGridScrollbar={true}
                            height={this.tableHeight}
                            rowHeight={this.getColumnHeight}
                            rowCount={this.props.state.translations.length + 1}
                            width={width}
                            scrollToColumn={0}
                            scrollToRow={0}
                        />
                    );
                }}
            </AutoSizer>
        );
    }


    /**
     * Render
     *
     * @return {React.ReactNode}
     */
    render(): React.ReactNode {
        return (
            <>
                <div className="c-table">
                    {
                        this.props.state.translations && this.props.state.translations.length > 0
                        ? this.renderTable()
                        : <TableNoData/>
                    }
                </div>
                {
                    this.state.isDeleteConfirmationPopupOpen
                    && this.renderDeleteConfirmationPopup()
                }
            </>
        );
    }
}

export default Table;
