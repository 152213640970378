import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import { mapStateToProps }      from '../../helpers';
import { ReduxComponentProps }  from '../../types/reduxComponentProps';
import { appSettings }          from '../../app.settings';

interface TableNoDataProps extends ReduxComponentProps {}

@connect(mapStateToProps)
class TableNoData extends PureComponent<TableNoDataProps> {
    render(): React.ReactNode {
        return (
            <div className="c-table__no-data">
                {
                    !(this.props.state.filter.dealerId || this.props.state.filter.carrierId || this.props.state.filter.search)
                    ? appSettings.text.filter_to_start_seeing_data
                    : appSettings.text.no_results
                }
            </div>
        );
    }
}

export default TableNoData;
