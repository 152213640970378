import React, { PureComponent } from 'react';
import './TitledBlock.scss';

interface TitledBlockProps {
    title: string;
    children: React.ReactNode;
    class?: string;
}

class TitledBlock extends PureComponent<TitledBlockProps> {

    constructor(props: TitledBlockProps) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <div className={`c-titled-block ${this.props.class ? this.props.class : ''}`}>
                <h4 className="c-titled-block__heading">{this.props.title}</h4>
                {this.props.children}
            </div>
        );
    }
}

export default TitledBlock;
