/**
 * Close Component Class
 * Wrapper for Closing Element by Clicking Outside or Escape Button
 */
import React, { PureComponent } from 'react';

interface CloseComponentProps {
    isOpen: boolean;
    closeChange: () => void;
}

class Close extends PureComponent<CloseComponentProps> {

    private wrapperRef: React.RefObject<HTMLDivElement>;

    /**
     * Constructor
     *
     * @param {CloseComponentProps} props
     */
    constructor(props: CloseComponentProps) {
        super(props);

        this.wrapperRef = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleEscClick     = this.handleEscClick.bind(this);
    }

    /**
     * Component Did Mount Lifecycle hook
     */
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleEscClick);
    }

    /**
     * Component Will Unmount Lifecycle hook
     */
    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleEscClick);
    }

    /**
     * Handle Click Outside
     *
     * @param e
     */
    handleClickOutside(e: any): void {
        if (this.props.isOpen && this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
            this.props.closeChange();
        }
    }

    /**
     * Handle Clicking by `esc`
     *
     * @param e
     */
    handleEscClick(e: any): void {
        if (e.keyCode === 27 && this.props.isOpen) {
            this.props.closeChange();
        }
    }

    /**
     * Render
     *
     * @return {React.ReactNode}
     */
    render(): React.ReactNode {
        return (
            <div className="c-close" ref={this.wrapperRef}>
                {this.props.children}
            </div>
        );
    }
}

export default Close;
