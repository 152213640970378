import React, { PureComponent } from 'react';
import './Textarea.scss';

interface TextareaProps {
    class?: string;
    placeholder: string;
    onChange?: (value: string) => void;
    value?: string;
    addTag?: string;
    valid?: boolean;
    disabled?: boolean;
}

interface TextareaState {
}

class Textarea extends PureComponent<TextareaProps, TextareaState> {

    private ref: React.RefObject<HTMLTextAreaElement>;

    cursorPosition: number;
    value: string;
    focused: boolean = false;

    constructor(props: TextareaProps) {
        super(props);

        this.ref = React.createRef();

        this.onChange = this.onChange.bind(this);
        this.onFocus  = this.onFocus.bind(this);
        this.onBlur   = this.onBlur.bind(this);
    }

    componentWillReceiveProps(nextProps: Readonly<TextareaProps>, nextContext: any): void {
        if (nextProps.addTag && !nextProps.value) {
            this.pasteTagToTheEmptyTextarea(nextProps);
        }

        if (nextProps.addTag && this.props.addTag !== nextProps.addTag && nextProps.value) {
            this.pasteTag(nextProps);
        }
    }

    pasteTagToTheEmptyTextarea(nextProps: Readonly<TextareaProps>): void {
        this.value            = `${nextProps.addTag}`;
        this.ref.current.value = this.value;
        if (this.props.onChange) this.props.onChange(this.value);
    }

    pasteTag(nextProps: Readonly<TextareaProps>): void {
        if (!this.focused) {
            this.cursorPosition = this.getCursorPos();
        }

        this.value            = `${
            nextProps.value.split('').slice(0, this.cursorPosition).join('')
            }${
            nextProps.addTag
            }${
            nextProps.value.split('').slice(this.cursorPosition).join('')
            }`;
        this.ref.current.value = this.value;
        if (this.props.onChange) this.props.onChange(this.value);
    }

    onChange(e): void {
        this.cursorPosition = this.getCursorPos();
        this.value          = (e.target as HTMLInputElement).value;
        if (this.props.onChange) this.props.onChange(this.value);
    }

    onFocus(): void {
        this.cursorPosition = this.getCursorPos();
        this.focused        = true;
    }

    onBlur(): void {
        this.focused = false;
    }

    getCursorPos() {
        return this.ref.current.selectionEnd;
    }

    render(): React.ReactNode {
        return (
            <textarea
                ref={this.ref}
                defaultValue={this.props.value || ''}
                className={`e-textarea ${this.props.valid === false ? 'e-textarea--danger' : ''} ${this.props.class ? this.props.class : ''}`}
                placeholder={this.props.placeholder}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                disabled={this.props.disabled}
            />
        );
    }
}

export default Textarea;
