import { Error }  from '../types/errors';
import { Action } from '../types/action';

enum ErrorsActionTypes {
    SET = 'translation-ui/errors/SET'
}

/**
 * Set The Error
 *
 * @param {Error} error
 * @return {{data: Error; type: ErrorsActionTypes}}
 */
export const setError = (error: Error) => ({
    type: ErrorsActionTypes.SET,
    data: error
});

/**
 * Reducer
 *
 * @param {Error[]} state
 * @param {Action<ErrorsActionTypes, Error[]>} action
 * @return {any}
 */
const errorsReducer = (state: Error[] = [], action: Action<ErrorsActionTypes, Error[]>) => {
    switch (action.type) {
        case ErrorsActionTypes.SET:
            return [...state, action.data];
        default:
            return state;
    }
};

export default errorsReducer;
