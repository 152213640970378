import React, { PureComponent } from 'react';
import './InputText.scss';

interface InputTextProps {
    class?: string;
    placeholder: string;
    onChange?: (value: string) => void;
    value?: string;
    valid?: boolean;
    disabled?: boolean;
}

interface InputTextState {

}

class InputText extends PureComponent<InputTextProps, InputTextState> {

    constructor(props: InputTextProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(e: any): void {
        const value: string = (e.target as HTMLInputElement).value.trim();

        if (this.props.onChange) this.props.onChange(value);
    }

    render(): React.ReactNode {
        return (
            <input
                type={'text'}
                defaultValue={this.props.value || ''}
                className={`e-input-text ${this.props.valid === false ? 'e-input-text--danger' : ''} ${this.props.class ? this.props.class : ''}`}
                placeholder={this.props.placeholder}
                onChange={this.onChange}
                disabled={this.props.disabled}
            />
        );
    }
}

export default InputText;
