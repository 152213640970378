import React, { PureComponent } from 'react';
import Close                    from '../../components/Close';
import Popup                    from '../../components/Popup';
import { connect }              from 'react-redux';
import { mapStateToProps }      from '../../helpers';
import { ReduxComponentProps }  from '../../types/reduxComponentProps';
import { closePopup }        from '../../ducks/popup';
import TranslationLanguage   from './TranslationLanguage';
import TranslationDealer     from './TranslationDealer';
import TranslationLabel      from './TranslationLabel';
import TranslationValue      from './TranslationValue';
import { PopupType }         from '../../enums/popupType';
import { appSettings }       from '../../app.settings';
import { createTranslation, editTranslation } from '../../ducks/dispatchers';
import { TableColumns }      from '../../enums/tableColumns';
import TranslationCarrier    from './TranslationCarrier';

interface PopupProps extends ReduxComponentProps {
}

interface PopupState {
    isLabelValid: boolean;
}

@connect(mapStateToProps)
class ActionPopup extends PureComponent<PopupProps, PopupState> {

    constructor(props: PopupProps) {
        super(props);

        this.state = {
            isLabelValid: true
        };

        this.onClose  = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.isLabelValid = this.isLabelValid.bind(this);
    }

    onClose(): void {
        this.props.dispatch(closePopup());
    }

    onSubmit(): void {
        if (this.props.state.popup.type === PopupType.ADD) {
            this.props.dispatch(createTranslation());
        } else {
            const t = this.props.state.popup.translation;
            this.props.dispatch(editTranslation(t.id, t[TableColumns.VALUE]));
        }
    }

    isLabelValid(isLabelValid: boolean): void {
        this.setState({isLabelValid});
    }

    disabledSubmitBtn(): boolean {
        const popup = this.props.state.popup;
        const t     = popup.translation;
        return !this.state.isLabelValid
            || !t
            || !t[TableColumns.VALUE]
            || !t[TableColumns.LANGUAGE]
            || !t[TableColumns.LABEL]
            || (t[TableColumns.VALUE] === popup.oldValue);
    }

    render(): React.ReactNode {
        const popupTitle = this.props.state.popup.type === PopupType.ADD
                           ? appSettings.text.add_new_translation
                           : appSettings.text.edit_translation;

        return (
            this.props.state.popup.isOpen &&
            <Close isOpen={this.props.state.popup.isOpen} closeChange={this.onClose}>
                <Popup
                    title={popupTitle}
                    onCancel={this.onClose}
                    onSubmit={this.onSubmit}
                    onClose={this.onClose}
                    shadowBgCenter={true}
                    width={'990px'}
                    disabledSubmit={this.disabledSubmitBtn()}
                >
                    <div className="l-flex l-margin-bottom--25">
                        <TranslationLanguage/>
                        <TranslationCarrier/>
                        <TranslationDealer/>
                        <TranslationLabel isValid={this.isLabelValid}/>
                    </div>

                    <div className="l-margin-top--12 l-flex">
                        <TranslationValue/>
                    </div>

                </Popup>
            </Close>
        );
    }
}

export default ActionPopup;
